import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { AUTOMATED_CAMPAIGN, CUSTOM_CAMPAIGN } from '../../actions/actionTypes';
import { createCampaign, getEmailFiles } from '../../actions/campaign';
import { getEndDate, getStartDate } from '../../helpers/campaign';
import TitleCard from '../common/TitleCard.jsx';
import CampaignTemplate from './CampaignTemplate';
import axios from 'axios';

const CampaignNew = props => {
  const {
    csrfToken,
    redirect_url,
    clinicId,
    templatesList,
    campaignType,
    currentCampaign,
    campaignEmails,
    isSuperAdmin = false,
    setActiveTab = null
  } = props;

  const [date, setDate] = useState(startOfDay(new Date()));

  // Helper to re-index days so they are consecutive after removal
  const reorderDays = list => {
    return list.map((item, index) => ({
      ...item,
      day: index + 1
    }));
  };

  const [inputList, setInputList] = useState(
    campaignEmails?.length > 0
      ? campaignEmails
      : [
          {
            day: 1,
            subject: null,
            body: null,
            send_date: campaignType === AUTOMATED_CAMPAIGN ? null : date,
            file: undefined
          }
        ]
  );

  useEffect(() => {
    // If not super admin, fetch files once
    if (!isSuperAdmin) {
      getEmailFiles(clinicId, currentCampaign.id, setInputList);
    }
    // Optionally add dependency array if you want it to run only when certain props change
    // }, [clinicId, currentCampaign.id, isSuperAdmin]);
  }, []);

  const [title, setTitle] = useState(currentCampaign?.title);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleInputChange = (e, index) => {
    const list = [...inputList];
    // File input
    if (e.target?.name === 'file') {
      list[index][e.target.name] = e.target.files[0];
    }
    // Typical input or text area
    else if (e.target) {
      const { name, value } = e.target;
      list[index][name] = value;
    }
    // Rich text editor or something returning value differently
    else {
      list[index]['body'] = e;
    }
    setInputList(list);
  };

  const handleDateChange = (newDate, index) => {
    const list = [...inputList];
    list[index]['send_date'] = newDate;
    setInputList(list);
  };

  const handleTemplateUse = (subject, body) => {
    const list = [...inputList];
    list[selectedIndex]['subject'] = subject;
    list[selectedIndex]['body'] = body;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const newList = [...inputList];
    newList.splice(index, 1);
    // Re-index the day property to keep them consecutive
    const finalList = reorderDays(newList);
    setInputList(finalList);
  };

  const handleAddClick = () => {
    // 'lastDay' is just the length of the list if we always keep them consecutive
    const newDay = inputList.length + 1;
    setInputList([
      ...inputList,
      {
        day: newDay,
        subject: null,
        body: null,
        send_date: campaignType === AUTOMATED_CAMPAIGN ? null : date,
        file: undefined
      }
    ]);
  };

  const handleCreateCampaign = status => {
    // Ensure file = undefined if not attached
    for (let i = 0; i < inputList.length; i += 1) {
      if (inputList[i].file == null) {
        inputList[i].file = undefined;
      }
    }

    const type =
      campaignType === AUTOMATED_CAMPAIGN
        ? AUTOMATED_CAMPAIGN
        : CUSTOM_CAMPAIGN;
    const totalDays = inputList.length; // Fixed typo from 'todatlDays' to 'totalDays'
    const minDate = getStartDate(inputList);
    const maxDate = getEndDate(inputList);

    const basePath = isSuperAdmin ? '/super_admin' : `/${clinicId}`;

    // If there's a currentCampaign, we update it; otherwise we create a new one
    const url = currentCampaign
      ? isSuperAdmin
        ? `${basePath}/update_campaign/${currentCampaign?.id}`
        : `${basePath}/campaigns/${currentCampaign?.id}`
      : isSuperAdmin
      ? `${basePath}/create_campaign`
      : `${basePath}/campaigns`;

    createCampaign(
      clinicId,
      csrfToken,
      redirect_url,
      inputList,
      title,
      type,
      totalDays,
      minDate,
      maxDate,
      url,
      currentCampaign ? axios.put : axios.post,
      status,
      isSuperAdmin,
      setActiveTab
    );
  };

  return (
    <div className='layout-content'>
      <div className='container-fluid flex-grow-1 container-p-y'>
        <TitleCard
          title={title}
          setTitle={setTitle}
          placeholder='Enter campaign name...'
        />

        {/*
          Sorting here is optional if we are always re-indexing (day = index + 1)
          but it's fine to keep if you want to be absolutely sure of the order.
        */}
        {inputList
          .sort((a, b) => a.day - b.day)
          .map((x, i) => {
            return (
              <React.Fragment key={i}>
                <CampaignTemplate
                  title={x.title}
                  subject={x.subject}
                  body={x.body}
                  sendDate={x.send_date}
                  index={i}
                  campaignType={campaignType}
                  setSelectedIndex={setSelectedIndex}
                  templatesList={templatesList}
                  handleRemoveClick={handleRemoveClick}
                  inputList={inputList}
                  handleTemplateUse={handleTemplateUse}
                  handleDateChange={handleDateChange}
                  handleChange={handleInputChange}
                  setInputList={setInputList}
                />
                <div className='btn-box'>
                  {inputList.length - 1 === i && (
                    <div className='float-right'>
                      <button
                        className='btn btn-primary mt-2'
                        onClick={handleAddClick}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add
                      </button>

                      {!isSuperAdmin && (
                        <button
                          className='btn btn-primary mt-2 ml-2'
                          onClick={() => handleCreateCampaign(false)}
                        >
                          Save Campaign
                        </button>
                      )}

                      <button
                        className='btn btn-primary mt-2 ml-2'
                        onClick={() => handleCreateCampaign(true)}
                      >
                        {currentCampaign && currentCampaign.status
                          ? 'Update'
                          : 'Create Campaign'}
                      </button>
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default CampaignNew;
